import {
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  PINTEREST,
  REDDIT,
  VIMEO,
  YOUTUBE,
  GOOGLE,
  YELP,
  TRIPADVISOR,
  OPENTABLE,
  TRUSTPILOT,
  TWITTER,
  TIKTOK,
  SNAPCHAT,
  TUMBLR,
  THREADS,
  BLUESKY,
} from './src/consts'

const post_tagging = 'Tag Users, Products, Collabs'
const post_customization = 'Customize Posts Per Profile'

const EMPLOYEE_ADVOCACY =
  'Amplify your social message with employee advocacy program enabling your employees to easily engage with your content. Free with 3 advocates. Pricing starts at $199 per month for 25 employees.'

const LISTENING =
  'Discover what people are saying about your brand, industry, and competitors. Listening for content on your social profiles is free with Vista Social. Listening across social, web and news starts at $75/month.'
const DATA_MIGRATION_DESCRIPTION =
  'Need to migrate historical analytics from an external source? We’ve got you covered!'

const ADVOCACY_PRICING = 'Free to try. $199/month for 25 employees.'

const LISTENING_PRICING = 'Free to track own profiles. $75/month to listen across social, web, news.'
const extraPlanFields = [
  {
    title: '',
    names: [
      {
        value: 'Monthly Price',
      },
      {
        value: 'Yearly Price',
      },
      {
        value: 'Included Profiles',
      },

      {
        value: 'Included Users',
      },
      {
        value: 'Extra Profiles',
      },

      {
        value: 'Extra Users',
      },
      {
        value: 'Employee Advocacy',
      },
      {
        value: 'Listening',
      },
    ],
  },
  {
    title: 'Planning and scheduling',
    names: [
      {
        value: 'Social Networks',
      },
      {
        value: 'Post Scheduling',
      },
      { value: 'Country Targeting' },
      {
        value: 'Calendar View',
      },

      {
        value: 'AI Assistant',
      },
      {
        value: 'Media Library',
      },
      {
        value: 'Draft Posts',
      },
      {
        value: post_customization,
      },
      {
        value: post_tagging,
      },
      {
        value: 'Optimal Time Recommendation',
      },

      {
        value: 'Post Labeling',
      },
      {
        value: 'Posting Schedule',
      },
      {
        value: 'Posting Schedule per Profile',
      },
      {
        value: 'Ideas',
      },
      {
        value: 'Saved Mentions',
      },
      {
        value: 'Saved Hashtags',
      },
      {
        value: 'Saved Captions',
      },

      {
        value: 'Canva Integration',
      },
      {
        value: 'Google, OneDrive, Dropbox',
      },
      {
        value: 'Trending Audio',
      },
      {
        value: 'Find Content',
      },
      {
        value: 'Hashtag Suggestions',
      },
      {
        value: 'Bulk Scheduling',
      },
      {
        value: 'Smart Scheduling',
      },

      {
        value: 'Link Shortener',
      },
      {
        value: 'Additional Comments',
      },
      {
        value: 'First Like',
      },

      {
        value: 'External Calendars',
      },
      {
        value: 'Custom Fields',
      },
      {
        value: 'External Profile Connection Link',
      },
      { value: 'Brand Safety & Compliance' },
    ],
  },

  {
    title: 'Collaboration',
    names: [
      {
        value: 'Calendar Notes',
      },
      {
        value: 'Post Comment Collaboration',
      },
      {
        value: 'Single-stage Approval Workflows',
      },
      {
        value: 'Multi-stage Approval Workflows',
      },
      {
        value: 'Shared Calendar',
      },
    ],
  },

  {
    title: 'Reporting',
    names: [
      {
        value: 'Social Media Report',
      },
      {
        value: 'Post Performance Report',
      },
      {
        value: 'Download Reports',
      },
      {
        value: 'Web Analytics Report',
      },
      {
        value: 'Review Reports',
      },

      {
        value: 'Competitor Reports',
      },
      {
        value: 'Schedule Reports',
      },
      {
        value: 'Custom Reports',
      },
      {
        value: 'Sentiment Analysis Reports',
      },
      {
        value: 'Industry Benchmark Reports',
      },
    ],
  },

  {
    title: 'Engagement',
    names: [
      {
        value: 'Social Inbox',
      },
      {
        value: 'Internal Notes',
      },
      {
        value: 'AI Assistant',
      },

      {
        value: 'Saved Replies',
      },
      {
        value: 'Message Labels',
      },
      {
        value: 'Spam Management',
      },
      {
        value: 'Task Management',
      },
      {
        value: 'Sentiment Analysis',
      },
    ],
  },
  {
    title: 'Reviews',
    names: [
      {
        value: 'Review Monitoring',
      },
      {
        value: 'Respond To Reviews',
      },
    ],
  },
  {
    title: 'Listening',
    names: [
      {
        value: 'Mentions',
      },
      {
        value: 'Comments',
      },
      {
        value: 'Direct Messages',
      },
    ],
  },
  {
    title: 'Vista Page link in bio tools',
    names: [
      {
        value: 'Vista Pages',
      },
      {
        value: 'Custom Domain',
      },
      {
        value: 'Google Tag Manager',
      },
    ],
  },
  {
    title: 'Security',
    names: [
      {
        value: 'User Permissions and Roles',
      },
      {
        value: 'Two Factor Authorization',
      },
    ],
  },
  {
    title: 'Data Integrations',
    names: [
      {
        value: 'Make.com',
      },
      {
        value: 'Slack',
      },
      {
        value: 'Zapier',
      },
    ],
  },
  {
    title: 'Support',
    names: [
      {
        value: 'Knowledge Base',
      },
      {
        value: 'Customer Support',
      },
      {
        value: 'Training',
      },
      {
        value: 'Dedicated Account Manager',
      },
      {
        value: 'Quarterly Account Review',
      },
    ],
  },
]

const plans = [
  {
    signup: true,
    active: false,
    name: 'Free',
    description:
      'For individuals and businesses getting started with social media with a need for basic scheduling and reporting tools.',
    price: {},
    benefits: [
      {
        name: '3 social profiles, 1 user',
        description: 'Includes 3 profiles and 1 user.',
      },
      {
        name: 'Basic publishing tools',
        description: 'Schedule up to 15 basic posts per profile every month.',
      },
      {
        name: 'Basic analytics',
        description: 'Use basic on-screen analytics to understand the performance of your profiles.',
      },
    ],
    addons: [
      {
        name: 'Employee Advocacy',
        description: EMPLOYEE_ADVOCACY,
        available: true,
      },
      {
        name: 'Listening',
        description: LISTENING,
        available: true,
      },
      {
        name: 'Data migration',
        description: DATA_MIGRATION_DESCRIPTION,
        available: false,
      },
    ],
    extra: {
      'Monthly Price': 'Always free',
      'Yearly Price': 'Always free',
      'Included Profiles': '3',

      'Included Users': '1',
      'Social Networks': [
        FACEBOOK,
        LINKEDIN,
        INSTAGRAM,
        GOOGLE,
        YOUTUBE,
        TIKTOK,
        REDDIT,
        PINTEREST,
        SNAPCHAT,
        TUMBLR,
        THREADS,
        BLUESKY,
      ],
      'Post Scheduling': '10 posts per profile per month',
      'Set Posting schedule': true,
      'AI Assistant': '100 credits/mo',
      'Calendar View': true,
      'Trending Audio': '',
      'Label Content': true,
      'Link Shortener': '',
      'First Like': '',
      'Saved Mentions': '',
      'Saved Hashtags': '',
      'Media Library': true,
      'Draft Posts': true,
      [post_customization]: true,

      'Social Media Report': true,

      'Schedule Reports': '',
      'Export Reports': '',
      'Branded Reports': '',

      'Social Inbox': true,
      'Message Labels': '',
      'Internal notes': '',
      'Engagement actions': true,
      'Vista Pages': true,
    },
  },

  {
    signup: true,
    active: true,
    name: 'Standard',
    description: 'For creators and businesses that need publishing, analytics & engagement tools.',
    price: {},
    benefits: [
      {
        name: '8 social profiles',
        description:
          'Find, collaborate, and schedule content for all your social channels. Visually schedule and preview your social media posts.',
      },
      {
        name: '1 user',
        description:
          'Find, collaborate, and schedule content for all your social channels. Visually schedule and preview your social media posts.',
      },
      {
        name: 'Planning & Publishing Tools',
        description:
          'Find, collaborate, and schedule content for all your social channels. Visually schedule and preview your social media posts.',
      },
      {
        name: 'Media Tools',
        description:
          'Upload, find and manage all of your images, videos, sounds and documents for seemless use in posts.',
      },

      {
        name: 'Engagement Tools',
        description:
          'Build relationships with your followers, and easily manage your social media messages, comments, and reviews in one place.',
      },
      {
        name: 'AI Assistant',
        description: 'Utilize the power of ChatGPT to generate amazing captions and message replies to save time!',
      },
      {
        name: 'Content Finders',
        description: 'Find content from trending social content and from royalty free repositories.',
      },
      {
        name: 'Reports',
        description:
          'Measure and report on the performance of your social media efforts. Identify what’s working and isn’t.',
      },
      {
        name: 'Vista Page link in bio tool',
        description:
          'Use Vista Page by Vista Social to create a stunning fully-customizable link in bio and landing pages for your business or brand.',
      },
      {},
    ],

    addons: [
      {
        name: 'Employee Advocacy',
        description: EMPLOYEE_ADVOCACY,
        available: true,
      },
      {
        name: 'Listening',
        description: LISTENING,
        available: true,
      },
      {
        name: 'Data migration',
        description: DATA_MIGRATION_DESCRIPTION,
        available: false,
      },
    ],

    extra: {
      'Monthly Price': '$39/month',
      'Yearly Price': '$374/year',
      'Included Profiles': '8',
      'Included Users': '1',

      'Social Networks': [
        FACEBOOK,
        LINKEDIN,
        INSTAGRAM,
        TWITTER,
        GOOGLE,
        YOUTUBE,
        TIKTOK,
        REDDIT,
        PINTEREST,
        SNAPCHAT,
        TUMBLR,
        THREADS,
        BLUESKY,
      ],

      'Post Scheduling': 'Unlimited',
      'Set Posting Schedule': true,
      'AI Assistant': '500 credits/mo',
      'Calendar View': true,
      'Label Content': true,
      'Optimal Time Recommendation': true,
      'Media Library': true,
      'Draft Posts': true,
      'Post Labeling': true,
      'Posting Schedule': true,
      'Posting Schedule per Profile': '1',
      'Post Comment Collaboration': true,
      'Google, OneDrive, Dropbox': true,
      Ideas: '100 ideas',
      [post_customization]: true,
      [post_tagging]: true,
      'Saved Mentions': true,
      'Saved Hashtags': true,
      'Saved Captions': true,
      'Canva Integration': true,
      'Trending Audio': true,
      'Calendar Notes': true,

      'Social Media Report': true,
      'Post Performance Report': true,

      'Download Reports': true,
      'Find Content': true,
      'Social Inbox': true,
      'Internal Notes': true,
      'Engagement Tools': true,
      'Message Labels': true,
      'Social CRM Tools': true,
      'Ad comments monitoring': '600 active ads per profile',
      'Listening Searches': true,
      'Automated Inbox Assistant': true,
      'Collision Detection': true,

      'Two Factor Authorization': true,
      'Saved Replies': true,
      Mentions: true,
      Comments: true,
      'Direct Messages': true,

      'Knowledge Base': true,
      'Customer Support': 'Email support',
      Training: 'Weekly webinar',

      'Vista Pages': true,

      'Employee Advocacy': ADVOCACY_PRICING,
      Listening: LISTENING_PRICING,
      'Hashtag Suggestions': true,
    },
  },

  {
    signup: true,
    active: true,
    name: 'Professional',
    description: 'For professionals and small teams needing collaboration tools and reporting.',
    price: {},
    benefits: [
      {
        name: '15 Social Profiles',
        description: 'Connect up to 15 social profiles.',
      },
      {
        name: '5 Users',
        description: 'Add up to 5 team users to your account.',
      },
      {
        name: 'Standard Plan Features, Plus:',
        description: 'All features of the Standard plan plus a lot more!',
      },
      {
        name: 'Bulk Scheduling',
        description: 'Bulk scheduling with options like CSV, RSS and more.',
      },
      {
        name: 'Post Workflow',
        description: 'Assign content to another user for approval.',
      },
      {
        name: 'Report Scheduling',
        description: 'Schedule reports to be delivered to team members and clients',
      },

      {
        name: 'Collaboration Tools',
        description: 'Collaborate with your team on content and community engagement.',
      },
      {
        name: 'Listening Tools',
        description: LISTENING,
      },
      {
        name: 'Review Management',
        description: 'Manage reviews across Facebook, Google, Yelp, Trip Advisor, OpenTable and TrustPilot.',
      },
      {},
    ],

    addons: [
      {
        name: 'Employee Advocacy',
        description: EMPLOYEE_ADVOCACY,
        available: true,
      },
      {
        name: 'Listening',
        description: LISTENING,
        available: true,
      },
      {
        name: 'Data migration',
        description: DATA_MIGRATION_DESCRIPTION,
        available: false,
      },
    ],

    extra: {
      'Monthly Price': '$79/month',
      'Yearly Price': '$758/year',
      'Included Profiles': '15',
      'Included Users': '5',

      'Social Networks': [
        FACEBOOK,
        LINKEDIN,
        INSTAGRAM,
        TWITTER,
        GOOGLE,
        YOUTUBE,
        TIKTOK,
        REDDIT,
        PINTEREST,
        SNAPCHAT,
        TUMBLR,
        THREADS,
        BLUESKY,
      ],

      'Post Scheduling': 'Unlimited',
      'Set Posting Schedule': true,
      'AI Assistant': '1000 credits/mo',
      [post_customization]: true,
      [post_tagging]: true,
      'Calendar View': true,
      'Bulk Scheduling': true,
      'Smart Scheduling': true,
      'Find Content': true,
      'Trending Audio': true,
      'Label Content': true,
      'Link Shortener': true,
      'Additional Comments': true,
      'First Like': true,
      'Saved Mentions': true,
      'Saved Hashtags': true,
      'Hashtag Suggestions': true,
      'Saved Captions': true,
      'Custom Fields': true,
      'Canva Integration': true,
      'Single-stage Approval Workflows': true,
      'Calendar Notes': true,
      'External Calendars': true,
      'Optimal Time Recommendation': true,
      'Media Library': true,
      'Draft Posts': true,
      'Post Labeling': true,
      'Posting Schedule': true,
      'Posting Schedule per Profile': 'Unlimited',
      'Post Comment Collaboration': true,
      'Google, OneDrive, Dropbox': true,
      Ideas: '1000 ideas',

      'Web Analytics Report': true,
      'Social Media Report': true,
      'Post Performance Report': true,
      'Review Reports': true,
      'Competitor Reports': true,

      'Schedule Reports': true,
      'Download Reports': true,

      'Social Inbox': true,
      'Internal Notes': true,
      'Message Labels': true,

      'Social CRM Tools': true,
      'Ad comments monitoring': '600 active ads per profile',
      'Listening Searches': true,
      'Automated Inbox Assistant': true,
      'Collision Detection': true,
      'Review Monitoring': [FACEBOOK, GOOGLE],
      'Respond To Reviews': true,
      'User Permissions and Roles': true,
      'Two Factor Authorization': true,

      'Task Management': true,

      'Saved Replies': true,
      'Spam Management': true,
      Mentions: true,
      Comments: true,
      'Direct Messages': true,
      'Knowledge Base': true,
      'Customer Support': 'Email + video call support',
      Training: 'Group training',
      'Vista Pages': true,
      'Employee Advocacy': ADVOCACY_PRICING,
      Listening: LISTENING_PRICING,
    },
  },

  {
    signup: true,
    active: true,
    name: 'Advanced',
    description: 'For teams and agencies looking for advanced features and power tools.',
    price: {},
    benefits: [
      {
        name: '30 Social Profiles',
        description: 'Connect up to 30 social profiles',
      },
      {
        name: '10 Users',
        description: 'Invite up to 10 users to your account.',
      },
      {
        name: 'Professional plan features, plus:',
        description: 'All features in Professional plan plus a lot a more!',
      },

      {
        name: 'Advanced scheduling',
        description: 'Advanced scheduling features such custom fields and smart scheduling.',
      },
      {
        name: 'Advanced workflows',
        description: 'Create sophisticated content approval workflows for internal and outside teams and clients.',
      },
      {
        name: 'Advanced reporting',
        description: 'Schedule and customize your reports.',
      },
      {
        name: 'Integrations (Zapier & Make)',
        description: 'Ability to integrate with external systems via Zapier and Make.com',
      },
      {
        name: 'Unlimited AI Assistant',
        description:
          'AI text generator for social media is a tool that uses artificial intelligence to automatically create social media content, such as posts, captions, or responses.',
      },
      {
        name: 'Advanced Vista Page features',
        description:
          'Implement custom domains for your Vista Page and integrate payments, calendars and more using Google Tag Manager',
      },
      {},
    ],

    addons: [
      {
        name: 'Employee Advocacy',
        description: EMPLOYEE_ADVOCACY,
        available: true,
      },
      {
        name: 'Listening',
        description: LISTENING,
        available: true,
      },
      {
        name: 'Data migration',
        description: DATA_MIGRATION_DESCRIPTION,
        available: false,
      },
    ],

    extra: {
      'Monthly Price': '$149/month',
      'Yearly Price': '$1,430/year',
      'Included Profiles': '30',
      'Included Users': '10',
      'Social Networks': [
        FACEBOOK,
        LINKEDIN,
        INSTAGRAM,
        TWITTER,
        GOOGLE,
        YOUTUBE,
        TIKTOK,
        REDDIT,
        PINTEREST,
        SNAPCHAT,
        TUMBLR,
        THREADS,
        BLUESKY,
      ],

      'Post Scheduling': 'Unlimited',
      'Country Targeting': true,
      'Set Posting Schedule': true,
      'AI Assistant': 'Unlimited',
      [post_customization]: true,
      [post_tagging]: true,
      'Calendar View': true,
      'Bulk Scheduling': true,
      'Smart Scheduling': true,
      'Find Content': true,
      'Trending Audio': true,
      'Label Content': true,
      'Link Shortener': true,
      'Additional Comments': true,
      'First Like': true,
      'Saved Mentions': true,
      'Saved Hashtags': true,
      'Hashtag Suggestions': true,
      'Saved Captions': true,
      'Custom Fields': true,
      'Canva Integration': true,
      'Single-stage Approval Workflows': true,
      'Multi-stage Approval Workflows': true,
      'Calendar Notes': true,
      'External Calendars': true,
      'Optimal Time Recommendation': true,
      'Media Library': true,
      'Draft Posts': true,
      'Post Labeling': true,
      'Posting Schedule': true,
      'Posting Schedule per Profile': 'Unlimited',
      'Post Comment Collaboration': true,
      'Shared Calendar': true,
      'Google, OneDrive, Dropbox': true,
      Ideas: '2000 ideas',

      'Web Analytics Report': true,
      'Social Media Report': true,
      'Post Performance Report': true,
      'Review Reports': true,
      'Competitor Reports': true,
      'Custom Reports': true,

      'Schedule Reports': true,
      'Download Reports': true,

      'Social Inbox': true,
      'Internal Notes': true,
      'Engagement Tools': true,
      'Message Labels': true,

      'Social CRM Tools': true,
      'Listening Searches': true,
      'Automated Inbox Assistant': true,
      'Collision Detection': true,
      'Review Monitoring': [FACEBOOK, GOOGLE, YELP, TRIPADVISOR, OPENTABLE, TRUSTPILOT],
      'Respond To Reviews': true,
      'User Permissions and Roles': true,
      'Two Factor Authorization': true,
      Zapier: true,
      Slack: true,
      'Make.com': true,
      'White Label': true,
      'Saved Replies': true,
      'Task Management': true,
      'Spam Management': true,
      Mentions: true,
      Comments: true,
      'Direct Messages': true,

      'Knowledge Base': true,
      'Customer Support': 'Email + video call support',
      Training: 'Group training',
      //  'Dedicated Account Manager': false,
      'Vista Pages': true,
      'Custom Domain': true,
      'Google Tag Manager': true,
      'Employee Advocacy': ADVOCACY_PRICING,
      Listening: LISTENING_PRICING,
    },
  },

  {
    signup: false,
    active: true,
    name: 'Enterprise',
    description: 'Enterprise solutions tailored to your business needs with custom integrations and support.',
    price: {},
    benefits: [
      {
        name: '80+ Social Profiles',
        description: 'Connect as many social profiles as you need.',
      },
      {
        name: '25+ Users',
        description: 'Invite as many users as you need to your account.',
      },
      {
        name: 'Sentiment Detection',
        description:
          'Prioritize messages, improve efficiency and monitor brand health with sentiment detection (positive, neutral, negative or mixed) on incoming messages, comments, mentions and reviews.',
      },
      {
        name: 'Premium Analytics',
        description: 'Gather more meaningful insights with Industry Benchmarking and Sentiment Aanalysis',
      },
      {
        name: 'Brand Safety & Compliance',
        description: 'Brand safety tools to protect your brand’s reputation. Effective compliance solution.',
      },
      {
        name: 'Dedicated Account Manager',
        description: 'A dedicated account manager will oversee your account and all projects related to your setup.',
      },
      {
        name: 'Onboarding & Training',
        description:
          'Our team will be with you every step of the way ensuring that your onboarding is quick and that your team receives the necessary training.',
      },

      {
        name: 'Custom Integrations',
        description: 'Includes any integrations with your existing systems.',
      },
      // {
      //   name: 'Tailored Implementation',
      //   description:
      //     'Tailored implementation and onboarding to get teams up and running quickly with customized onboarding, training, integration and data migration.',
      // },
      // {
      //   name: 'Consulting services',
      //   description:
      //     'Any ongoing projects that you may need for our team to be engaged in to help your business succeed on social.',
      // },
      {
        name: 'Quarterly Account Reviews',
        description:
          'Quarterly reviews help fine tune your marketing strategy and to ensure that you take advantage of all available features.',
      },
      // {
      //   name: 'and more....',
      //   description:
      //     'When we develop custom solutions the sky is truly the limit. We will tailor our solution to your specific requirements.',
      // },
      {},
    ],

    addons: [
      {
        name: 'Employee Advocacy',
        description: EMPLOYEE_ADVOCACY,
        available: true,
      },
      {
        name: 'Listening',
        description: LISTENING,
        available: true,
      },
      {
        name: 'Data migration',
        description: DATA_MIGRATION_DESCRIPTION,
        available: true,
      },
    ],

    extra: {
      'Monthly Price': '$379/month',
      'Yearly Price': '$3,638/year',
      'Extra Profiles': '%per_profile%/%billing_interval% per profile',
      'Extra Users': true,
      'Included Profiles': '80 or more',

      'Included Users': '25 or more',
      'Social Networks': [
        FACEBOOK,
        LINKEDIN,
        INSTAGRAM,
        TWITTER,
        GOOGLE,
        YOUTUBE,
        TIKTOK,
        REDDIT,
        PINTEREST,
        SNAPCHAT,
        TUMBLR,
        THREADS,
        BLUESKY,
      ],

      [post_customization]: true,
      [post_tagging]: true,
      'Post Scheduling': 'Unlimited',
      'Country Targeting': true,
      'Set Posting Schedule': true,
      'AI Assistant': 'Unlimited',
      'Calendar View': true,
      'Bulk Scheduling': true,
      'Smart Scheduling': true,
      'Find Content': true,
      'Trending Audio': true,
      'Label Content': true,
      'Link Shortener': true,
      'Additional Comments': true,
      'First Like': true,
      'Saved Mentions': true,
      'Saved Hashtags': true,
      'Hashtag Suggestions': true,
      'Saved Captions': true,
      'Custom Fields': true,
      'Canva Integration': true,
      'Single-stage Approval Workflows': true,
      'Multi-stage Approval Workflows': true,
      'Calendar Notes': true,
      'External Calendars': true,
      'Optimal Time Recommendation': true,
      'Media Library': true,
      'Draft Posts': true,
      'Post Labeling': true,
      'Posting Schedule': true,
      'Posting Schedule per Profile': 'Unlimited',
      'Post Comment Collaboration': true,
      'Shared Calendar': true,
      'Google, OneDrive, Dropbox': true,
      Ideas: 'Unlimited',

      'Web Analytics Report': true,
      'Social Media Report': true,
      'Post Performance Report': true,
      'Review Reports': true,
      'Competitor Reports': true,
      'Custom Reports': true,

      'Schedule Reports': true,
      'Download Reports': true,

      'Social Inbox': true,
      'Internal Notes': true,
      'Engagement Tools': true,
      'Message Labels': true,

      'Social CRM Tools': true,
      'Ad comments monitoring': '600 active ads per profile',
      'Listening Searches': true,
      'Automated Inbox Assistant': true,
      'Collision Detection': true,
      'Review Monitoring': [FACEBOOK, GOOGLE, YELP, TRIPADVISOR, OPENTABLE, TRUSTPILOT],
      'Respond To Reviews': true,
      'User Permissions and Roles': true,
      'Two Factor Authorization': true,
      Zapier: true,
      Slack: true,
      'Make.com': true,
      'White Label': true,

      'Task Management': true,
      'Saved Replies': true,
      'Spam Management': true,
      Mentions: true,
      Comments: true,
      'Direct Messages': true,
      'Knowledge Base': true,
      'Customer Support': 'Priority email + video call support',
      Training: '1-on-1 training',
      'Dedicated Account Manager': true,
      'Quarterly Account Review': true,
      'Vista Pages': true,
      'Custom Domain': true,
      'Google Tag Manager': true,
      'Employee Advocacy': ADVOCACY_PRICING,
      'Sentiment Analysis Reports': true,
      'Sentiment Analysis': true,
      'Industry Benchmark Reports': true,
      'External Profile Connection Link': true,
      'Brand Safety & Compliance': true,
      Listening: LISTENING_PRICING,
    },
  },
]

const getFPTid = () => {
  try {
    return window.FPROM && window.FPROM.data.tid
  } catch (error) {
    console.error('failed to generate checkout reference id', error)
    return undefined
  }
}

const getFPTrefid = () => {
  try {
    return window.FPROM && window.FPROM.data.ref_id
  } catch (error) {
    console.error('unable to get fp ref id', error)
    return undefined
  }
}

module.exports = {
  plans,
  extraPlanFields,
  getFPTid,
  getFPTrefid,
}
